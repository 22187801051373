<template>
  <section class="centred-content">
    <div class="content contents-fades-at-bottom" ref="content">
      <h3>{{ data.title[0].value }}</h3>
      <p v-html="sanitise(data.body[0].value)"></p>
    </div>
  </section>
</template>

<script>
import { httpMixin } from "@/mixins/httpMixin.js";
import { visualMixin } from "@/mixins/visualMixin.js";
import ScrollMagic from "scrollmagic";

export default {
  mixins: [httpMixin, visualMixin],

  props: {
    data: Object
  },

  mounted: function() {
    new ScrollMagic.Scene({
      triggerElement: this.$refs.content,
      offset: 0,
      triggerHook: 0.8,
      reverse: false
    })
      .setClassToggle(this.$refs.content, "content--visible")
      .addTo(this.animationController);
  }
};
</script>

<style scoped>
section {
  display: flex;
  align-items: center;
}

.content {
  text-align: left;
  position: relative;
  transition: transform 0.9s ease-in-out, all 0.9s ease-in-out;
  transform: translateY(20%);
  opacity: 0;
  padding: 0 max(64px, 20%) 100px;
}

.content--visible {
  transform: none;
  opacity: 1;
}

p {
  line-height: 1.6;
}
</style>
