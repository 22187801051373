import { render, staticRenderFns } from "./CentredParagraph.vue?vue&type=template&id=31011b62&scoped=true&"
import script from "./CentredParagraph.vue?vue&type=script&lang=js&"
export * from "./CentredParagraph.vue?vue&type=script&lang=js&"
import style0 from "./CentredParagraph.vue?vue&type=style&index=0&id=31011b62&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31011b62",
  null
  
)

export default component.exports